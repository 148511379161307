import React, { useState } from "react";
import style from "./level-body.module.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useNavigate, useParams, useHistory } from "react-router-dom";
import { Flare, Height } from "@mui/icons-material";

function LevelBody() {
  const { username, estructure } = useParams();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [nivel, setNivel] = useState("");

  const handleClose = () => setShow(false);
  function handleShow(e) {
    setShow(true);
    setNivel(e);
  }

  const handleGoToAlertsMessages = () => {
    navigate(`/home/${username}/level/${estructure}/alerts/${nivel}`);

    if (nivel === "1") {
      localStorage.setItem("nivel", "Nivel 1");
      localStorage.setItem("level", "1");
    }
    if (nivel === "2") {
      localStorage.setItem("nivel", "Nivel 2");
      localStorage.setItem("level", "2");
    }
    if (nivel === "3") {
      localStorage.setItem("nivel", "Nivel 3");
      localStorage.setItem("level", "3");
    }
    if (nivel === "4") {
      localStorage.setItem("nivel", "Simulado");
      localStorage.setItem("level", "4");
    }
    if (nivel === "5") {
      localStorage.setItem("nivel", "EFNI");
      localStorage.setItem("level", "5");
    }
  };

  return (
    <div className={style["body-home"]}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{textAlign: "Center"}}>
            Você selecionou 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {nivel === "1" ? (
            <div className={style["button-estructure-nivel1"]}>Nível 1</div>
          ) : nivel === "2" ? (
            <div className={style["button-estructure-nivel2"]}>Nível 2</div>
          ) : nivel === "3" ? (
            <div className={style["button-estructure-nivel3"]}>Nível 3</div>
          ) : nivel === "4" ? (
            <div className={style["button-estructure-simulado"]}>Simulado</div>
          ) : (
            <div className={style["button-estructure-efni"]}>EFNI</div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="primary"
            onClick={handleGoToAlertsMessages}
            style={{
              width: "60%",
              height: "80px",
              fontSize: "1.5rem",
              height: "80%",
            }}
          >
            Confirmar
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              width: "60%",
              height: "80px",
              fontSize: "1.5rem",
              height: "80%",
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className={style["body-body-home"]}>
        <p>Relato de Emergência</p>
        <div className={style["content-body-home"]}>
          <div className={style['title-nivel']}>Escolha o nível de emergência atual</div>
          <button
            className={style["button-estructure-nivel1"]}
            onClick={() => handleShow("1")}
          >
            Nível 1
          </button>
          <button
            className={style["button-estructure-nivel2"]}
            onClick={() => handleShow("2")}
          >
            Nível 2
          </button>
          <button
            className={style["button-estructure-nivel3"]}
            onClick={() => handleShow("3")}
          >
            Nível 3
          </button>
          <button
            className={style["button-estructure-simulado"]}
            onClick={() => handleShow("4")}
          >
            Simulado
          </button>
          <button
            className={style["button-estructure-efni"]}
            onClick={() => handleShow("5")}
          >
            EFNI
          </button>
        </div>
        <div style={{ height: "50px" }}></div>
      </div>
    </div>
  );
}

export default LevelBody;
