import React, { useEffect, useState } from "react";
import style from "./confirmation.module.css";

import axios from "axios";

import MMILogo from "../../images/mmi_sipremo.jpg";

import Camouflage from "../../components/body-home/camouflage/camouflage";
import { useNavigate } from "react-router-dom";

function Confirmation() {
  const [confirmations, setConfirmations] = useState([]);
  const [camouflage, setCamouflage] = useState(false);

  const navigate = useNavigate();


  async function getAllCommunication() {
    let data = [];


    // altere o get  para esse rota para relizar testes internos
    // http://34.207.150.2:8000/communication/communication_mnt/all

    await axios
      .get("https://mcs.sipremo.com/api/operacao/get_all_comunication")
      .then((res) => {
        res.data.map((element) => {
          console.log(element);
          if (element.active) {
            data.push(element);
          }
        });
        setConfirmations(data.reverse());
        setCamouflage(true);
      });
  }

  
  useEffect(() => {
    // let navigate = useNavigate();

    getAllCommunication();
    if (!localStorage.getItem("loginInReport")) {
      navigate("/relatorio");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 120000);
  });

  return camouflage ? (
    <div className={style["main"]}>
      <div className={style["header"]}>
        <div className={style["image"]}>
          <img src={MMILogo}></img>
        </div>
      </div>
      <div className={style["body"]}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Elemento de Notificação</th>
              <th scope="col">Contato</th>
              <th scope="col">PWA</th>
              <th scope="col">SMS</th>
              <th scope="col">Email</th>
              <th scope="col">Whatsapp</th>
              <th scope="col">Confirmação</th>
            </tr>
          </thead>
          <tbody>
            {confirmations.map(
              (confirmation, key) => (
                //

                // confirmation.active ? (
                <tr key={key}>
                  <th scope="row">
                    {confirmation.recipient.contact_info.name}
                  </th>
                  <td>{confirmation.recipient.sector.name}</td>
                  <td>{confirmation.recipient.contact_info.phone}</td>
                  <td>
                    {confirmation.timestamp.date +
                      " " +
                      confirmation.timestamp.time}
                  </td>
                  <td>
                    {confirmation.timestamp.date +
                      " " +
                      confirmation.timestamp.time}
                  </td>
                  <td>
                    {confirmation.timestamp.date +
                      " " +
                      confirmation.timestamp.time}
                  </td>
                  <td>
                    {confirmation.timestamp.date +
                      " " +
                      confirmation.timestamp.time}
                  </td>
                  <td>
                    {confirmation.confirmation.date +
                      " " +
                      confirmation.confirmation.time}
                  </td>
                </tr>
              )
              // ) : null
            )}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <Camouflage />
  );
}

export default Confirmation;
