// Rotas.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from "../pages/signin/signin"
import Home from '../pages/Home/home';
import Level from "../pages/Level/level";
import AlertsMessage from '../pages/Alerts/alerts';
import Confirmation from '../pages/confirmations/confirmation';
import SignInConfirmation from '../pages/SignInConfirmation/sign-in-confirmation';
import Users from '../pages/User/Users';

function Rotas() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/home/:username" element={<Home />} />
        <Route path="/home/:username/level/:estructure" element={<Level/>}/>
        <Route path="/home/:username/level/:estructure/alerts/:level" element={<AlertsMessage/>}/>
        <Route path="/home/:username/level/:estructure/alerts/:level" element={<AlertsMessage/>}/>
        
        <Route path="/relatorio" element={<SignInConfirmation />} />
        <Route path="/relatorio/confirmation" element={<Confirmation />} />
      </Routes>
    </Router>
  );
}

export default Rotas;