import React, { useEffect, useState } from "react";
import style from "./lowHierarchyBody.module.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";


function LowHierarchyBody({ ultimateMessage }) {
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  async function confirmationNotification(date, time) {
    let body = {
      date: date,
      time: time,
    };

    // altere o post para esse rota para relizar testes internos
    //`http://34.207.150.2:8000/communication/communication_mnt/confirmation?username=${user.username}`
    await axios
      .post(
        `https://mcs.sipremo.com/api/operacao/confirmation_mensage?username=${user.username}`,
        body
      )
      .then((res) => {
        setConfirm(true);
        setShow(true);
      })
      .catch((err) => {
        notifyError("Erro ao registrar a confirmação");
      });
  };
  // console.log("teste")




  const collectedDateToday = () => {
    const date = new Date();

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();

    return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year.padStart(
      4,
      "0"
    )}`;
  };

  const collectedHourToday = () => {
    const date = new Date();

    const hour = date.getHours().toString();
    const minute = date.getMinutes().toString();
    const second = date.getSeconds().toString();

    return `${hour.padStart(2, "0")}:${minute.padStart(
      2,
      "0"
    )}:${second.padStart(2, "0")}`;
  };

  const handleConfirmation = () => {
    confirmationNotification(collectedDateToday(), collectedHourToday());

    setTimeout(() => {
      handleClose();
    }, 5000);
  };

  const handleClose = () => {
    setShow(false);
  };

  const notifyError = (text) => toast.error(text);

  return (
    <div className={style["body-home"]}>
      <Modal
        show={show}
        style={{
          marginTop: "25vh",
        }}
      >
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            flexDirection: "column",
          }}
        >
          Confirmação Validada!
          <CheckCircleIcon
            style={{
              width: "40vw",
              height: "40vw",
              color: "rgb(000, 200, 200)",
            }}
          />
        </Modal.Body>
      </Modal>
      <div className={style["body-body-home"]}>
        <div className={style["content-body-home"]}>
          <div className={style["title-body-home"]}>
            <p>Alerta de Emergência</p>
          </div>
          <div className={style["message-home"]}>
            {ultimateMessage.message ? (
              <p>{ultimateMessage.message.content}</p>
            ) : (
              <p></p>
            )}
            {/* {}
            <LevelOne/>
            <LevelTwo/>
            <LevelThree/>
            <Simulate/>
            <EFNI/> */}

          </div>
        </div>
        {ultimateMessage.confirmation ? (
          ultimateMessage.confirmation.date !== "" ? (
            <></>
          ) : !confirm ? (
            <button className={style["button"]} onClick={handleConfirmation}>
              Confirmar
            </button>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default LowHierarchyBody;
