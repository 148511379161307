import React from "react";
import style from "./sign-in-confirmation.module.css";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logoMMI from "../../images/mmi_sipremo.jpg";

import axios from "axios";


function SignInConfirmation() {
  const [user, setUser] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [handleChangeStyle, setHandleChangeStyle] = React.useState({
    display: "none",
  });

  const navigate = useNavigate();

  const handleChangeUser = (value) => {
    setUser(value.target.value);
  };

  const handleChangePassword = (value) => {
    setPassword(value.target.value);
  };

  const notUser = () => {
    let style = {
      display: "block",
      color: "red",
      marginTop: "0.6rem",
    };
    setHandleChangeStyle(style);
  };

  const notifyError = (text) => toast.error(text);

  const handleLogin = async (e) => {
      axios
        .get(`https://mcs.sipremo.com/api/user/user?username=${user}`)
        .then(({ data }) => {
          if (data.username === user && data.password === password) {

            
            let user = {
              email: data.contact_info.email,
              name: data.contact_info.name,
              document: data.contact_info.document,
              address: data.contact_info.address,
              phone: data.contact_info.phone,
              group: data.sector.group,
              hierarchy_database: data.sector.hierarchy,
              username: data.username,
            }
            // console.log(data)
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("loginInReport", true);
            navigate(`/relatorio/confirmation`);
          } else {
            notifyError("Usuário Não Encontrado");
            notUser();
          }
        })
        .catch((err) => {
          notifyError("Usuário Não Encontrado");
        });

    

  };

  return (
    <div className={style["main-container"]}>
      <main className={style.container}>
        <div className={style["image"]}>
          <img src={logoMMI} className={style["image-image"]}></img>
        </div>
        <h1>Acesso ao Relatório</h1>
        <hr style={{width: "100%"}}></hr>
        <h2>Login</h2>
        <form>
          <div className={style["input-field"]}>
            <p>
              Usuário <e className={style["required"]}>*</e>
            </p>
            <input
              type="text"
              name="user"
              id="user"
              placeholder="usuário"
              value={user}
              onChange={handleChangeUser}
            ></input>
            <div className={style.underline}></div>
          </div>

          <p style={handleChangeStyle}>Campo Necessário.</p>

          <div className={style["input-field"]}>
            <p>
              Senha <e className={style["required"]}>*</e>
            </p>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="senha"
              value={password}
              onChange={handleChangePassword}
            ></input>
            <div className={style.underline}></div>
          </div>

          <p style={handleChangeStyle}>campo Necessário.</p>

          <input
            className={style["button"]}
            type="button"
            value="Login"
            onClick={handleLogin}
          ></input>
        </form>

        <ToastContainer />
      </main>
    </div>
  );
}

export default SignInConfirmation;
