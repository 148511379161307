import React, { useState } from "react";
import style from "./alerts-body.module.css";
import logoMMI from "../../images/mmi_sipremo.jpg";

import { useNavigate, useParams, useHistory } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function AlertsBody() {
  const { username, estructure, level } = useParams();
  const [templates, setTemplates] = useState([]);
  const [descriptionTemplate, setDescriptionTemplate] = useState("");
  const [nameTemplate, setNameTemplate] = useState("");
  const [idTemplate, setIdTemplate] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  

  const [PWA, setPWA] = useState(true);
  const [SMS, setSMS] = useState(true);
  const [EMAIL, setEMAIL] = useState(true);
  const [WPP, setWPP] = useState(true);

  const [show, setShow] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const local = localStorage.getItem("local");
  const nivel = localStorage.getItem("nivel");

  React.useEffect(() => {
    get_templates();
  }, []);

  async function get_templates() {
    let estruct;

    if (estructure === "B1-Aux") {
      estruct = "B1 Aux";
    } else {
      estruct = estructure;
    }

    let response = "";

    if (user.name === "L0 H0") {
      response = await axios.get(
        `https://mcs.sipremo.com/api/template/template/all?level=${level}&hierarchy=${
          parseInt(user.hierarchy_database) + 1
        }&group=coord&structure=${estruct}`
      );
    } else {
      response = await axios.get(
        `https://mcs.sipremo.com/api/template/template/all?level=${level}&hierarchy=1&group=coord&structure=${estruct}`
      );
    }

    if((response.data).length == 1) {
      setDescriptionTemplate(response.data[0].template_content);
    setNameTemplate(response.data[0].template_desc);
    setIdTemplate(response.data[0].template_id);
    }
    
    setTemplates(response.data);

    return response;
  }

  async function get_users() {
    const comm_users_endpoint = `https://mcs.sipremo.com/api/user/users?hierarchy=${parseInt(
      user.hierarchy_database
    )}&group=${user.group}`;

    const response = await axios.get(comm_users_endpoint);

    return response;
  }

  const postCommunication = async () => {
    let comm_type = [];

    if (EMAIL) {
      comm_type.push("EMAIL");
    }

    if (WPP) {
      comm_type.push("WHATSAPP");
    }

    if (SMS) {
      comm_type.push("SMS");
    }

    const contentPost = {
      comm_type: comm_type,
      hierarchy: "1",
      group: "efni",
      message: {
        content: descriptionTemplate,
        is_template: true,
        subject: `Mensagem de Alerta - ${nameTemplate}`,
        template_id: idTemplate,
      },
      timestamp: {
        date: date,
        time: time,
      },
    };

    // altere o post para esse rota para relizar testes internos
    //http://34.207.150.2:8000/communication/communication_mnt

    // const comm_users_endpoint = `http://34.207.150.2:8000/communication/communication`;
    // token ====>  e19c1c2f4b7e6d8f3a9b5c2e1d8f4b6e7a9c5f2d3e4b7a8c6f3e2d1b9a5f8c4d3e7a9b6c1d5e8f2b3a4c7e6d9f1b5a2c8f4e7d3
    let token = "e19c1c2f4b7e6d8f3a9b5c2e1d8f4b6e7a9c5f2d3e4b7a8c6f3e2d1b9a5f8c4d3e7a9b6c1d5e8f2b3a4c7e6d9f1b5a2c8f4e7d3";
    let comm_users_endpoint = `https://mcs.sipremo.com/api/operacao/send_mensage_all_users?token=${token}`;

    if (localStorage.getItem("level") == 1) {
      // notifySuccess("Enviado para usuarios de nivel 0 e 1 da collection all");
      comm_users_endpoint = `https://mcs.sipremo.com/api/operacao/send_mensage_hierarcy_0_1?token=${token}`;
    } else if (
      localStorage.getItem("level") == 2 ||
      localStorage.getItem("level") == 3 ||
      localStorage.getItem("level") == 4
    ) {
      // notifySuccess("Enviado para usuarios de nivel 0, 1 e 2 da collection all")
      comm_users_endpoint = `https://mcs.sipremo.com/api/operacao/send_mensage_all_users?token=${token}`;
    } else {
      comm_users_endpoint = `https://mcs.sipremo.com/api/operacao/send_mensage_efni?token=${token}`;
      // notifySuccess("Enviado para usuarios efni");
    }

    setShow(false);
    notifyInformation("Enviando menssagens, aguarde alguns instantes");
    await axios
      .post(comm_users_endpoint, contentPost)
      .then((res) => {
        notifySuccess("Comunicado Enviado Com Sucesso!");
        console.log(res)
      })
      .catch((err) => {
        notifyError("Erro ao Enviar o Comunicado!");
      });
  };

  const handleTemplateSelected = async (e) => {

    if(e.target.value){

      setDescriptionTemplate(JSON.parse(e.target.value).template_content);
      setNameTemplate(JSON.parse(e.target.value).template_desc);
      setIdTemplate(JSON.parse(e.target.value).template_id);
    }else{
      setDescriptionTemplate("");
      setNameTemplate("");
      setIdTemplate("");
    }
  };

  const collectedDateToday = () => {
    const date = new Date();

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();

    return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year.padStart(
      4,
      "0"
    )}`;
  };

  const collectedHourToday = () => {
    const date = new Date();

    const hour = date.getHours().toString();
    const minute = date.getMinutes().toString();
    const second = date.getSeconds().toString();

    return `${hour.padStart(2, "0")}:${minute.padStart(
      2,
      "0"
    )}:${second.padStart(2, "0")}`;
  };

  const handleCommitMenssage = () => {
    setDate(collectedDateToday());
    setTime(collectedHourToday());

    setShow(true);
  };

  const handleCancelCommitMessage = () => {
    setShow(false);
  };

  const handleChangePWA = () => {
    setPWA(!PWA);
  };
  const handleChangeSMS = () => {
    setSMS(!SMS);
  };
  const handleChangeEMAIL = () => {
    setEMAIL(!EMAIL);
  };
  const handleChangeWPP = () => {
    setWPP(!WPP);
  };

  const notifyError = (text) => toast.error(text);

  const notifySuccess = (text) => toast.success(text);

  const notifyInformation = (text) => toast.info(text);

  React.useEffect(() => {
    get_users();
  }, []);

  return (
    <div className={style["body-home"]}>
      <Modal show={show} onHide={handleCancelCommitMessage}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontSize: "0.9rem",
            }}
          >
            A Seguinte mensagem será enviada pelos canais de comunicação aos
            responsáveis:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Estamos acionando os responsáveis para alertar seus respectivos
          setores correlacionados para Alerta {nivel} para {local}. O motivo do
          alerta é {nameTemplate}.
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="primary"
            onClick={postCommunication}
            style={{
              width: "60%",
              height: "80px",
              fontSize: "1.5rem",
              height: "80%",
            }}
          >
            Confirmar
          </Button>
          <Button
            variant="secondary"
            onClick={handleCancelCommitMessage}
            style={{
              width: "60%",
              height: "80px",
              fontSize: "1.5rem",
              height: "80%",
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className={style["body-body-home"]}>
        <div className={style["content-body-home"]}>
          <div className={style["image"]}>
            <img src={logoMMI} className={style["image-image"]}></img>
          </div>
          {/* <button onClick={get_users}>teste</button> */}
          <p>Relato de Emergência</p>

          <select
            
            className={style["select-template"]}
            onChange={(value) => handleTemplateSelected(value)}
          >
            <option key={"aaaa"} value={""}>Selecione um Template</option>

            {templates.map((template, key) => (
              <option key={key} value={JSON.stringify(template)}>
                {template.template_desc}
              </option>
            ))}
          </select>

          <label className={style["label-template"]}>
            {descriptionTemplate}
          </label>

          {level === "1" ? (
            <div className={style["button-estructure-nivel1"]}>Nível 1</div>
          ) : level === "2" ? (
            <div className={style["button-estructure-nivel2"]}>Nível 2</div>
          ) : level === "3" ? (
            <div className={style["button-estructure-nivel3"]}>Nível 3</div>
          ) : level === "4" ? (
            <div className={style["button-estructure-simulado"]}>Simulado</div>
          ) : (
            <div className={style["button-estructure-efni"]}>EFNI</div>
          )}
          <div className={style["paragraph-observation"]}>
            <p>
              Esta notificação será enviada aos responsáveis através dos
              seguintes canais
            </p>
          </div>

          <div className={style["option-radio"]}>
            <input type="checkbox" checked={PWA} onChange={handleChangePWA} />
            <div>PWA</div>
          </div>
          <div className={style["option-radio"]}>
            <input type="checkbox" checked={SMS} onChange={handleChangeSMS} />
            <div>SMS</div>
          </div>
          <div className={style["option-radio"]}>
            <input
              type="checkbox"
              checked={EMAIL}
              onChange={handleChangeEMAIL}
            />
            <div>E-mail</div>
          </div>
          <div className={style["option-radio"]}>
            <input type="checkbox" checked={WPP} onChange={handleChangeWPP} />{" "}
            <div>Whatsapp</div>
          </div>

          <Button
            style={{
              marginTop: "25px",
              width: "70%",
              height: "40px",
              marginBottom: "25px",
            }}
            onClick={handleCommitMenssage}
          >
            Enviar
          </Button>
        </div>
        <div style={{ height: "50px" }}></div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default AlertsBody;
