import React, { useEffect, useState } from "react";
import style from "./nav-bar.module.css";

import ClearIcon from "@mui/icons-material/Clear";
import ReplyIcon from "@mui/icons-material/Reply";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

import { useNavigate } from "react-router-dom";

function NavBar({ menu, navOpen }) {
  const navigate = useNavigate();

  const [user, setUser] = useState();

  const handleUser = async () => {
    let u = await localStorage.getItem("user");
    setUser(JSON.parse(u).hierarchy_database)
  };

  const handleCloseNav = () => {
    navOpen(!menu);
  };

  const handleBackHistory = () => {
    window.history.back();
  };

  const handleExit = () => {
    localStorage.clear();
    navigate("/");
  };


  useEffect(() => {
    handleUser();
  }, []);

  return (
    <div
      className={`${
        menu ? style["navbar-body-open"] : style["navbar-body-close"]
      } `}
    >
      <div className={style["close-button"]}>
        <ClearIcon className={style["ClearIcon"]} onClick={handleCloseNav} />
      </div>

      <button className={style["project-body"]} onClick={handleBackHistory}>
        <ReplyIcon className={style["ClearIcon"]} />
        Voltar
      </button>
      <hr></hr>
      <button className={style["project-body"]} onClick={handleExit}>
        <LogoutIcon className={style["ClearIcon"]} />
        Sair
      </button>
      <hr></hr>
    </div>
  );
}

export default NavBar;
